import React, { useState } from 'react';
import './Home.css';
import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter';
import ImageDisplayer from '../components/ImageDisplayer';
import ViewImage from '../components/ViewImage';
import HappyTone from '../../images/assets/happy-tone.jpg';
import GrinchTone from '../../images/assets/grinch-tone.jpg';
import GubbyTone from '../../images/assets/gubby-tone.jpg';
import DazedTone from '../../images/assets/dazed-tone.jpg';

const Home = () => {
  const [collectionType, setCollectionType] = useState('about');
  const [showViewImage, setShowViewImage] = useState(false);
  const [loginOrRegister, setLoginOrRegister] = useState(false);
  const [imageToShow, setImageToShow] = useState({
    url: 'nothing',
    desc: 'nothing',
  });

  return (
    <>
      <div className="home-page">
        <PageHeader setCollectionType={setCollectionType} />
        <div className="page-content">
          {collectionType === 'about' ? (
            <>
              <h1>ABOUT</h1>
              <p id="about-desc">
                Toni Brown Aka Regatron Bronter Aka The Tone Bone Aka Reagan, is
                an aspiring artist spawning from the depths of Wrexham. This
                website is a store front and online exhibition of Regan's
                drawings, tattoo desgins and a vast range of multi-media art
              </p>
              <div className="toni-container">
                <img className="about-image" src={HappyTone} />
                <img className="about-image" src={GrinchTone} />
                <img className="about-image" src={GubbyTone} />
                <img className="about-image" src={DazedTone} />
              </div>
            </>
          ) : null}
          {collectionType === 'account' ? (
            <>
              <h1>Your Raccoon Finger Account</h1>
              <p>
                Access your account to track orders, save favorite pieces, and
                receive exclusive updates on new artwork and limited edition
                releases.
              </p>
              {loginOrRegister ? (
                <>
                  <h1>Login</h1>
                </>
              ) : (
                <>
                  <h1>Register</h1>
                  <label>Email:</label>
                  <input
                    type="text"
                    className="login-input"
                    id="register-email"
                  />

                  <input type="password" className="login-input" />
                  <input type="password" className="login-input" />
                </>
              )}
              <button onClick={() => setLoginOrRegister(false)}>Login</button>
              <button onClick={() => setLoginOrRegister(true)}>Signup</button>
            </>
          ) : null}
          {collectionType !== 'account' && collectionType !== 'about' ? (
            showViewImage ? (
              <ViewImage
                currentImage={imageToShow}
                setShowViewImage={() => {
                  setShowViewImage(false);
                }}
              />
            ) : (
              <ImageDisplayer
                collectionType={collectionType}
                setShowViewImage={() => {
                  setShowViewImage(true);
                }}
                setImageToShow={(image: { url: string; desc: string }) => {
                  setImageToShow(image);
                }}
              />
            )
          ) : null}
        </div>
        <PageFooter />
      </div>
    </>
  );
};

export default Home;
