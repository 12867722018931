import React from 'react';
import './Components.css';

interface ViewImageProps {
  currentImage: { url: string; desc: string };
  setShowViewImage: () => void;
}

const ViewImage = (props: ViewImageProps) => {
  return (
    <>
      <p onClick={props.setShowViewImage}>Back</p>
      <div className="view-image-container">
        <h1>{props.currentImage.desc}</h1>
        <img src={props.currentImage.url} className="image" alt={`Image`} />
      </div>
    </>
  );
};

export default ViewImage;
