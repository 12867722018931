import React, { useState } from 'react';
import './Login.css';
import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter';

const Login = () => {
  const [collectionType, setCollectionType] = useState('paintings');

  return (
    <>
      <PageHeader setCollectionType={setCollectionType} />
      <h1>Login</h1>
      <PageFooter />
    </>
  );
};

export default Login;
