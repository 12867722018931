import React from 'react';
import './Components.css';

interface PageFooterProps {}

const PageFooter = (props: PageFooterProps) => {
  return (
    <>
      <div className="page-footer">
        <div id="footer-contact-info">
          <h2>Contact Info</h2>
          <p>Tele: </p>
        </div>
        <div id="footer-legal">
          <h2>Legal Biz</h2>
          <p>Testing</p>
        </div>
      </div>
    </>
  );
};

export default PageFooter;
