import React, { useState } from 'react';
import './Components.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../images/assets/temp-logo.png';

interface PageHeaderProps {
  setCollectionType: React.Dispatch<React.SetStateAction<string>>;
}

const PageHeader = (props: PageHeaderProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className="page-header">
        <div className="logo-area-image">
          <img src={logo} className="logo" alt="logo" />
        </div>
        <p></p>
      </div>
      <div className="nav-main">
        <button onClick={toggleDropdown} className="nav-button">
          Menu
        </button>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <button
              className="nav-button"
              onClick={() => {
                toggleDropdown();
                props.setCollectionType('about');
              }}
            >
              About
            </button>
            <button
              className="nav-button"
              onClick={() => {
                toggleDropdown();
                props.setCollectionType('tattoos');
              }}
            >
              Tattoos
            </button>
            <button
              className="nav-button"
              onClick={() => {
                toggleDropdown();
                props.setCollectionType('ceramics');
              }}
            >
              Ceramics
            </button>
            <button
              className="nav-button"
              onClick={() => {
                toggleDropdown();
                props.setCollectionType('paintings');
              }}
            >
              Paintings
            </button>
            <button
              className="nav-button"
              onClick={() => {
                toggleDropdown();
                props.setCollectionType('drawings');
              }}
            >
              Drawings
            </button>
            <button
              className="nav-button"
              onClick={() => {
                toggleDropdown();
                props.setCollectionType('account');
              }}
            >
              Account
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PageHeader;
